import { type FieldProps } from '~/server/services/templates/fields';
import { dateTimeService } from '~/utils/date-time.service';
import { numberService } from '~/utils/number.service';
import { type FieldType } from '~db/types';

const renderOption: Render = ({ value, field }) => {
  if (!field?.options || field.options.length === 0) return value as string;

  const option = field.options.find((option) => option.value === value);
  return option?.label ?? (value as string);
};
const renderOptions: Render = ({ value: values, field }) => {
  if (!field?.options || field.options.length === 0) return values as string;
  if (!Array.isArray(values)) return renderOption({ value: values, field });
  return values.map((value) => renderOption({ value, field })).join(', ');
};

type Render = ({ value, field }: { value: unknown; field?: FieldProps }) => string;

const mapFieldToHtmlString: Partial<Record<FieldType, Render>> = {
  TEXT: ({ value }) => value as string,
  TEXTAREA: ({ value }) => value as string,
  JSON: ({ value }) => JSON.stringify(value, null, 2),
  CHECKBOX: ({ value }) => (value ? '✅' : '❌'),
  COLOR: ({ value }) => `<span style="color: ${value as string}">${value as string}</span>`,
  NUMBER: ({ value }) => numberService.tryFormat(value),
  IMAGE_URL: ({ value }) => value as string,
  SINGLE_SELECT: renderOption,
  MULTI_SELECT: renderOptions,
  LOOKUP_USER: renderOption,
  LOOKUP_CARD: renderOption,
  EMAIL: ({ value }) => `<a href="mailto:${value as string}">${value as string}</a>`,
  PHONE: ({ value }) => value as string,
  URL: ({ value }) => value as string,
  DATE: ({ value }) => (value ? dateTimeService.tryFormat(value) : ''),
  DATE_TIME: ({ value }) => (value ? dateTimeService.tryFormatDateTime(value) : ''),
  TIME: ({ value }) => (value as string) || '',
  CURRENCY: ({ value, field }) => numberService.tryCurrency(value, field?.settings?.currency) || '',
  AGE: ({ value }) => dateTimeService.toAge(value),
  RADIO: renderOption,
};

const mapFieldToString: Partial<
  Record<FieldType, ({ value, field }: { value: unknown; field?: FieldProps }) => string>
> = {
  ...mapFieldToHtmlString,
  COLOR: ({ value }) => value as string,
  EMAIL: ({ value }) => value as string,
};

export const fieldDisplayStringUtils = {
  renderOption,
  renderOptions,
  mapFieldToHtmlString,
  mapFieldToString,
};
